import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useI18n } from 'components/I18nProvider';
import { Spin, List, Button } from 'antd';
import Page from 'components/Page';
import { fetchClientLatencies, fetchSegmentClients } from 'api/segment';
const triggerBlobDownload = (filename, blob) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
};
const SegmentReportPage = () => {
    const { eventId, segmentId } = useParams();
    const { intl } = useI18n();
    const [clients, setClients] = useState('loading');
    useEffect(() => {
        const fetchClientsState = async () => {
            if (eventId === undefined || segmentId === undefined) {
                return;
            }
            const fetchedClients = await fetchSegmentClients(eventId, segmentId);
            if (fetchedClients === null) {
                return;
            }
            setClients(fetchedClients);
        };
        fetchClientsState();
    }, [eventId, segmentId]);
    const handleDownload = async (clientId) => {
        if (eventId === undefined || segmentId === undefined) {
            return;
        }
        const csvBlob = await fetchClientLatencies(eventId, segmentId, clientId);
        if (csvBlob === null) {
            return;
        }
        const filename = `${eventId}-${segmentId}-${clientId}.csv`;
        triggerBlobDownload(filename, csvBlob);
    };
    return (_jsxs(Page, { children: [_jsx("h1", { children: intl.formatMessage({ id: 'latencyReport' }) }), clients === 'loading' ? _jsx(Spin, {}) : (_jsx(List, { dataSource: clients, renderItem: (client) => (_jsxs(List.Item, { children: [client, " ", _jsxs(Button, { onClick: () => handleDownload(client), children: [intl.formatMessage({ id: 'download' }), " CSV"] })] })) }))] }));
};
export default SegmentReportPage;
