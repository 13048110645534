// This module tracks the server time and provides a function to get the current server time
// for latency calculations.
// 
// # Explanation of the sync algorithm
//
// The time sync is done by sending a request to the server and measuring the round trip time (RTT).
//
// Server timeline           |-- sync handshake -----|  
// UNIX timestamp in MS           T1   T = T1 + RTT/2        S
// -------------------------------+----+---------------------+--------------
//                               / \   .                     .                    
//                              /   \  .                     .
//                             /     \ .                     .
//                            /       \.                     .
// --------------------------+---------+---------------------+--------------
// Client timeline           R         M                     C
// performance counter in MS
// To measure the end-to-end latency, on the clinet we need to know the current server time S at some
// moment C. To achieve this, the client repeatedly performs the sync handshake with the server to 
// update its notion of the server time. 
// 
// The sync handshake works as follows:
// At the moment R the client sends a sync request to the server. The request arrives at time T1 and
// the server sends response with the value of time T1, which is the UNIX time in milliseconds.
// The client receives the response at point M. The round trip time (RTT) is measured as M - R using 
// the JS performance counter. The server time T at the moment M is thus T1 + RTT/2. 
//
// The moment M is our synchronization mark. We store the server time T and the client time M in the
// the syncMark object. This object is updated every SYNC_INTERVAL milliseconds by sending a new
// request to the server and producing a new sync mark.
//
// Whenever on the client side we need to know the current server time S at some moment C, we can calculate
// it using the formula: S = T + C - M. This formula is used in the getCurrentServerTime function.
import { buildPath } from "./resolveUrl";
import { io } from "socket.io-client";
const SYNC_INTERVAL = 10_000; // 10 seconds
const syncMark = {
    serverTimeMs: 0,
    clientMarkMs: 0,
};
let start = null;
export const initSyncedClock = () => {
    const requestServerTime = () => {
        if (start !== null) {
            return;
        }
        socket.emit('requestSync');
        start = performance.now();
    };
    const socket = io('/timeSync', {
        path: buildPath('socket.io'),
        forceNew: true,
    });
    socket.on('serverTime', (serverTime) => {
        if (start === null) {
            return;
        }
        const now = performance.now();
        const roundTripTime = now - start;
        syncMark.serverTimeMs = Math.round(serverTime + roundTripTime / 2);
        syncMark.clientMarkMs = now;
        start = null;
        setTimeout(requestServerTime, SYNC_INTERVAL);
    });
    requestServerTime();
};
export const getCurrentServerTime = () => {
    return Math.round(syncMark.serverTimeMs + performance.now() - syncMark.clientMarkMs);
};
