import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Select, Skeleton, Switch } from 'antd';
import { useI18n } from 'components/I18nProvider';
import { sortLocaleOptions } from 'lib/locales';
import TwoColumnPanel from '../TwoColumnPanel';
import styles from './style.module.scss';
const localeFilter = (input, option) => (option !== undefined && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0);
const Column = TwoColumnPanel.Column;
const TranslationPanel = ({ form, outputLanguages, disabled = false }) => {
    const { intl } = useI18n();
    const inputLanguage = Form.useWatch('inputLanguage', form);
    const translationEnabled = Form.useWatch('translationEnabled', form) ?? false;
    const prepareLocaleOptions = (locales) => sortLocaleOptions(locales.map((lang) => ({
        label: intl.formatMessage({ id: lang }),
        value: lang,
        disabled: lang === inputLanguage,
    })));
    const presets = [
        {
            label: intl.formatMessage({ id: 'delayed' }),
            value: 'delayed',
        },
        {
            label: intl.formatMessage({ id: 'refining' }),
            value: 'refining',
        },
        {
            label: intl.formatMessage({ id: 'basic' }),
            value: 'basic',
        }
    ];
    return (_jsx(TwoColumnPanel, { title: _jsxs("div", { className: styles.cardHead, children: [_jsx("span", { children: intl.formatMessage({ id: 'liveTranslation' }) }), _jsx(Form.Item, { name: "translationEnabled", noStyle: true, children: _jsx(Switch, { checkedChildren: intl.formatMessage({ id: 'on' }), unCheckedChildren: intl.formatMessage({ id: 'off' }), disabled: disabled }) })] }), children: translationEnabled ? (_jsxs(_Fragment, { children: [_jsx(Column, { children: _jsx(Form.Item, { name: "outputLanguages", label: intl.formatMessage({ id: 'outputLanguages' }), children: _jsx(Select, { mode: "multiple", style: { width: '100%' }, placeholder: intl.formatMessage({ id: 'pleaseSelect' }), filterOption: localeFilter, options: prepareLocaleOptions(outputLanguages), disabled: disabled || !translationEnabled }) }) }), _jsx(Column, { children: _jsx(Form.Item, { name: "translationPreset", label: intl.formatMessage({ id: 'translationPreset' }), children: _jsx(Select, { placeholder: "Please select", options: presets, disabled: disabled || !translationEnabled }) }) })] })) : _jsx(Skeleton, { paragraph: { rows: 1 } }) }));
};
export default TranslationPanel;
