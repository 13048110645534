import { apiUrl, authFetch } from "./base";
export const fetchAllEvents = async () => {
    const response = await authFetch('api/events');
    if (!response.ok) {
        throw new Error(`Failed to fetch events: ${(await response.json()).errors[0]}`);
    }
    const payload = await response.json();
    return payload.data;
};
export const fetchEvent = async (eventId) => {
    const response = await authFetch(`api/events/${eventId}`);
    if (!response.ok) {
        if (response.status === 404) {
            return 'not-found';
        }
        throw new Error(`Failed to fetch event: ${(await response.json()).errors[0]}`);
    }
    const payload = await response.json();
    return payload.data;
};
export const fetchPublicEventById = async (eventId) => {
    const response = await fetch(apiUrl(`api/public/events/${eventId}`));
    if (!response.ok) {
        if (response.status === 404) {
            return 'not-found';
        }
        throw new Error(`Failed to fetch event: ${(await response.json()).errors[0]}`);
    }
    const payload = await response.json();
    return payload.data;
};
export const createEvent = async (title, description) => {
    const response = await authFetch('api/events', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            title,
            description,
        }),
    });
    if (!response.ok) {
        throw new Error(`Failed to insert event: ${(await response.json()).errors[0]}`);
    }
    const json = await response.json();
    return json.data;
};
export const updateEventId = async (eventId, newId) => {
    const response = await authFetch(`api/events/${eventId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: newId }),
    });
    if (!response.ok) {
        if (response.status === 409) {
            return 'already-exists';
        }
        throw new Error(`Failed to update event: ${(await response.json()).errors[0]}`);
    }
    const json = await response.json();
    return json.data;
};
export const deleteEvent = async (eventId) => {
    const response = await authFetch(`api/events/${eventId}`, {
        method: 'DELETE',
    });
    if (!response.ok) {
        throw new Error(`Failed to delete event: ${(await response.json()).errors[0]}`);
    }
};
export const deleteSegment = async (eventId, segmentId) => {
    const response = await authFetch(`api/events/${eventId}/segments/${segmentId}`, {
        method: 'DELETE',
    });
    if (!response.ok) {
        throw new Error(`Failed to delete segment: ${(await response.json()).errors[0]}`);
    }
};
