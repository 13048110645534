import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { fontSizes } from "../subtitles-settings";
import { useSearchParams } from "react-router-dom";
import styles from './style.module.scss';
const SubtitlesPanel = ({ subtitles, settings }) => {
    const linesRef = useRef(null);
    const [searchParams] = useSearchParams();
    const scrollText = () => {
        if (linesRef.current === null) {
            return;
        }
        const scrollHeight = linesRef.current.scrollHeight;
        const clientHeight = linesRef.current.clientHeight;
        const scrollTop = linesRef.current.scrollTop;
        if (clientHeight + scrollTop < scrollHeight) {
            linesRef.current.scrollTop += 12;
        }
    };
    useEffect(() => {
        if (searchParams.get('autoScroll') === 'off') {
            return;
        }
        const interval = setInterval(scrollText, 25);
        return () => clearInterval(interval);
    }, []);
    return (_jsx("main", { className: styles.subtitles, children: _jsx("div", { className: styles.lines, ref: linesRef, style: {
                fontSize: `${fontSizes[settings.fontSize]}em`,
            }, children: subtitles === 'waiting' ? (_jsx("div", { className: styles.waiting })) : (_jsxs(_Fragment, { children: [_jsx("span", { className: styles.text, children: subtitles.text }), _jsx("span", { className: styles.text, children: subtitles.lastPhrase })] })) }) }));
};
export default SubtitlesPanel;
