import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Flex, Form, Input, Space } from "antd";
import { useI18n } from "components/I18nProvider";
import { useRef } from "react";
const VALID_CODE_PATTERN = /^[a-z0-9][a-z0-9-]{3,30}[a-z0-9]$/;
const EventCodeForm = ({ initialCode, buttonLoading, autoFocus, inputLead, submitLabel, gap, onSubmit, }) => {
    const { intl } = useI18n();
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const validateMessages = {
        required: intl.formatMessage({ id: 'eventCodeRequired' }),
        pattern: {
            mismatch: intl.formatMessage({ id: 'invalidEventCode' }),
        },
    };
    const handleSubmit = (values) => {
        onSubmit(values.code);
    };
    return (_jsx(Form, { validateMessages: validateMessages, layout: "vertical", form: form, initialValues: { code: initialCode ?? '' }, onFinish: handleSubmit, children: _jsxs(Flex, { align: "baseline", gap: gap, ref: formRef, children: [inputLead, _jsxs(Space.Compact, { children: [_jsx(Form.Item, { name: "code", rules: [{
                                    type: 'string',
                                    required: true,
                                    pattern: VALID_CODE_PATTERN,
                                }], children: _jsx(Input, { autoFocus: autoFocus }) }), _jsx(Button, { loading: buttonLoading, htmlType: "submit", type: "primary", children: submitLabel })] })] }) }));
};
export default EventCodeForm;
