import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useI18n } from "components/I18nProvider";
import SubtitlesHeader from "./SubtitlesHeader";
import SubtitlesPanel from "./SubtitlesPanel";
import SubtitlesFooter from "./SubtitlesFooter";
import { io } from "socket.io-client";
import styles from './style.module.scss';
import { loadSubtitlesSettings, storeSubtitlesSettings } from "./subtitles-settings";
import { IdleTimer } from "./idleTimer";
import AlertPanel from "./AlertPanel";
import { AudioMutedOutlined, CloseCircleFilled, Loading3QuartersOutlined } from "@ant-design/icons";
import { fetchPublicEventById } from "api/events";
import { getCurrentServerTime } from "lib/server-clock";
import { processLatency } from "./latency-counter";
import { BASE_PATH, buildPath } from "lib/resolveUrl";
import { isTestingMode } from "components/TestingMode";
const idleTimer = new IdleTimer();
const getSubtitlesLanguage = (suggestedLanguage, segment) => {
    if (suggestedLanguage === segment.audioLanguage) {
        return segment.audioLanguage;
    }
    if (segment.translation === 'off') {
        return segment.audioLanguage;
    }
    const result = segment.translation.languages.find((lang) => lang === suggestedLanguage);
    return result ?? segment.audioLanguage;
};
const SubtitlesPage = ({ eventId }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const socketRef = useRef();
    const [event, setEvent] = useState('loading');
    const [subtitlesSettings, setSubtitlesSettings] = useState(loadSubtitlesSettings);
    const [content, setContent] = useState('inactive');
    const [showControls, setShowControls] = useState(true);
    const { intl } = useI18n();
    const language = searchParams.get('lang');
    useEffect(() => {
        idleTimer.onIdle(() => {
            setShowControls(false);
        });
        idleTimer.onActive(() => {
            setShowControls(true);
        });
        idleTimer.mount();
        return () => {
            idleTimer.unmount();
        };
    }, []);
    const createLatencyReport = (fragment) => (processLatency(fragment, getCurrentServerTime()));
    const navigateToLanguage = (nextLanguage) => {
        if (event === 'loading' || event === 'not-found') {
            return;
        }
        const nextParams = new URLSearchParams(searchParams);
        if (nextLanguage === null) {
            nextParams.delete('lang');
        }
        else {
            nextParams.set('lang', nextLanguage);
        }
        const stringParams = nextParams.toString();
        const params = stringParams === '' ? '' : `?${stringParams}`;
        const url = `${BASE_PATH}${params}#${event.id}`;
        navigate(url, { replace: true });
    };
    const initEvent = async () => {
        if (eventId === undefined) {
            return;
        }
        const event = await fetchPublicEventById(eventId);
        setEvent(event);
        if (event === 'not-found') {
            return;
        }
        const socket = io('/subtitles', {
            path: buildPath('socket.io'),
            query: { eventId: event.id },
            forceNew: true,
        });
        socket.on('activeSegment', (activeSegment) => {
            if (activeSegment === 'none-active') {
                setContent('inactive');
                return;
            }
            const subtitlesLanguage = getSubtitlesLanguage(language, activeSegment);
            socket.emit('selectLanguage', {
                language: subtitlesLanguage,
            });
            if (language !== subtitlesLanguage) {
                navigateToLanguage(subtitlesLanguage === activeSegment.audioLanguage
                    ? null
                    : subtitlesLanguage);
            }
            setContent({
                segment: activeSegment,
                transcript: 'waiting',
            });
        });
        socket.on('words', (fragment) => {
            const latencyReport = createLatencyReport(fragment);
            socket.emit('latency', latencyReport);
            setContent((prev) => {
                if (prev === 'loading' || prev === 'inactive') {
                    return prev;
                }
                return {
                    ...prev,
                    transcript: {
                        subtitles: {
                            text: prev.transcript === 'waiting'
                                ? fragment.text
                                : prev.transcript.subtitles.text + fragment.text,
                            lastPhrase: '',
                        },
                        latency: latencyReport,
                    },
                };
            });
        });
        socket.on('phrase', (fragment) => {
            const latencyReport = createLatencyReport(fragment);
            socket.emit('latency', latencyReport);
            setContent((prev) => {
                if (prev === 'loading' || prev === 'inactive') {
                    return prev;
                }
                return {
                    ...prev,
                    transcript: {
                        subtitles: {
                            text: prev.transcript === 'waiting' ? '' : prev.transcript.subtitles.text,
                            lastPhrase: fragment.text,
                        },
                        latency: latencyReport,
                    }
                };
            });
        });
        socketRef.current = socket;
    };
    useEffect(() => {
        initEvent();
    }, []);
    const goFullScreen = async () => {
        try {
            await document.documentElement.requestFullscreen();
        }
        catch (error) {
            console.error('Native fullscreen failed, using css as fallback.');
        }
    };
    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };
    const handleSettingsChange = async (changedValues) => {
        const newSettings = {
            ...subtitlesSettings,
            ...changedValues,
        };
        setSubtitlesSettings(newSettings);
        storeSubtitlesSettings(newSettings);
        if ('fullscreen' in changedValues) {
            if (changedValues.fullscreen) {
                void goFullScreen();
            }
            else {
                exitFullScreen();
            }
        }
    };
    const handleLanguageChange = (language) => {
        if (content === 'inactive' || content === 'loading') {
            return;
        }
        socketRef.current?.emit('selectLanguage', { language });
        navigateToLanguage(language === content.segment.audioLanguage
            ? null
            : language);
    };
    let panel = null;
    let latency = 'none';
    if (content === 'loading' || event === 'loading') {
        panel = (_jsx(AlertPanel, { icon: _jsx(Loading3QuartersOutlined, { spin: true }), title: intl.formatMessage({ id: 'loading' }), size: "small" }));
    }
    else if (event === 'not-found') {
        panel = (_jsx(AlertPanel, { icon: _jsx(CloseCircleFilled, {}), title: intl.formatMessage({ id: 'eventNotFound' }), message: intl.formatMessage({ id: 'eventNotFoundDesc' }) }));
    }
    else if (content === 'inactive') {
        panel = (_jsx(AlertPanel, { icon: _jsx(AudioMutedOutlined, {}), title: intl.formatMessage({ id: 'noActiveSegment' }), message: intl.formatMessage({ id: 'noActiveSegmentDesc' }) }));
    }
    else {
        latency = isTestingMode()
            ? content.transcript === 'waiting'
                ? 'waiting'
                : content.transcript.latency
            : 'none';
        panel = (_jsx(SubtitlesPanel, { subtitles: content.transcript === 'waiting' ? 'waiting' : content.transcript.subtitles, settings: subtitlesSettings }));
    }
    return (_jsxs("div", { "data-theme": subtitlesSettings.theme, className: clsx(styles.subtitlesPage, { [styles.noControls]: !showControls }, { [styles.noUserSelect]: searchParams.get('autoScroll') !== 'off' }), children: [_jsx(SubtitlesHeader, { latency: latency }), panel, content === 'loading' || content === 'inactive' ? _jsx("footer", {}) : (_jsx(SubtitlesFooter, { settings: subtitlesSettings, language: getSubtitlesLanguage(language, content.segment), onSettingsChange: handleSettingsChange, onLanguageChange: handleLanguageChange, segment: content.segment }))] }));
};
export default SubtitlesPage;
