import { jsx as _jsx } from "react/jsx-runtime";
import { buildPath } from "lib/resolveUrl";
import styles from './style.module.scss';
import { toggleTestingMode } from "components/TestingMode";
const HeaderLogo = () => {
    const handleTestingMode = (e) => {
        if (e.ctrlKey) {
            toggleTestingMode();
        }
    };
    return (_jsx("img", { className: styles.logo, src: buildPath('static/img/app-logo.svg'), alt: "Logo", onDoubleClick: handleTestingMode }));
};
export default HeaderLogo;
