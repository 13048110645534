import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Form } from "antd";
import SegmentPanel from "../SegmentPanel";
import RecordingPanel from "../RecordingPanel";
import { startSegment } from "api/segment";
import TranslationPanel from "../TranslationPanel";
import { isTestingMode } from "components/TestingMode";
import styles from './style.module.scss';
const NewSegmentSection = ({ event, config, recording, audioInputDevices, testRecordings, onSegmentEnded, }) => {
    const [form] = Form.useForm();
    const engines = config.s2tEngines;
    const initialValues = {
        title: `Segment ${event.segmentsCount + 1}`,
        description: '',
        deviceId: null,
        testRecordingId: testRecordings[0].id,
        inputLanguage: engines[0].languages[0],
        outputLanguages: [],
        translationEnabled: false,
        translationPreset: 'refining',
        engine: engines[0].name,
    };
    useEffect(() => {
        form.setFieldValue('title', `Segment ${event.segmentsCount + 1}`);
    }, [event.segmentsCount]);
    const handleSubmit = async (values) => {
        if (recording.phase === 'starting') {
            return;
        }
        if (audioInputDevices === 'denied') {
            return;
        }
        if (recording.phase === 'in-progress') {
            recording.stop();
            onSegmentEnded();
            return;
        }
        const segment = await startSegment(event.id, {
            segmentTitle: values.title,
            audioLanguage: values.inputLanguage,
            translation: values.translationEnabled
                ? {
                    languages: values.outputLanguages,
                    preset: values.translationPreset,
                }
                : 'off',
            engine: values.engine,
        });
        const inputSource = (() => {
            if (isTestingMode()) {
                const testRecording = testRecordings.find((recording) => recording.id === values.testRecordingId);
                return {
                    type: 'recording',
                    path: testRecording.path,
                    title: testRecording.title,
                };
            }
            return {
                type: 'mic',
                deviceInfo: audioInputDevices.find((device) => device.deviceId === values.deviceId)
            };
        })();
        recording.start(inputSource, segment.eventId, segment.id);
    };
    return (_jsxs(Form, { className: styles.form, form: form, labelWrap: true, layout: 'vertical', initialValues: initialValues, onFinish: handleSubmit, children: [_jsx(SegmentPanel, { form: form, engines: config.s2tEngines, disabled: recording.phase !== 'idle' }), _jsx(TranslationPanel, { form: form, outputLanguages: config.outputLanguages, disabled: recording.phase !== 'idle' }), _jsx(RecordingPanel, { form: form, audioInputDevices: audioInputDevices, testRecordings: testRecordings, recording: recording })] }));
};
export default NewSegmentSection;
