const latenciesWindow = [];
const averageLatencies = (latencies) => {
    const sum = latencies.reduce((acc, lat) => ({
        e2e: acc.e2e + lat.e2e,
        transmit: acc.transmit + lat.transmit,
        transcribe: acc.transcribe + lat.transcribe,
        translate: acc.translate + lat.translate,
    }), { e2e: 0, transmit: 0, transcribe: 0, translate: 0 });
    return {
        e2e: Math.round(sum.e2e / latencies.length),
        transmit: Math.round(sum.transmit / latencies.length),
        transcribe: Math.round(sum.transcribe / latencies.length),
        translate: Math.round(sum.translate / latencies.length),
    };
};
export const processLatency = (timestamps, serverTime) => {
    const e2e = serverTime - timestamps.speechStamp;
    const transcribe = timestamps.transcribedStamp - timestamps.transmitStamp;
    const translate = timestamps.translatedStamp !== null
        ? timestamps.translatedStamp - timestamps.transcribedStamp
        : 0;
    const transmit = e2e - transcribe - translate;
    if (latenciesWindow.length > 15) {
        latenciesWindow.shift();
    }
    latenciesWindow.push({ e2e, transmit, transcribe, translate });
    return averageLatencies(latenciesWindow);
};
