const getBaseUrlPath = () => {
    const baseElement = document.head.querySelector('base');
    if (baseElement === null) {
        throw new Error('Base element not found');
    }
    return new URL(baseElement.href).pathname;
};
export const BASE_PATH = getBaseUrlPath();
export const buildPath = (path) => {
    return `${BASE_PATH}${path ?? ''}`;
};
