import { jsx as _jsx } from "react/jsx-runtime";
import { BASE_PATH } from 'lib/resolveUrl';
import { createRoot } from 'react-dom/client';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import SubtitlesPage from 'pages/SubtitlesPage';
import { ConfigProvider, App } from 'antd';
import { theme } from 'ant-config';
import HomePage from 'pages/HomePage';
import EventsPage from 'pages/EventsPage';
import EventPage from 'pages/EventPage';
import TranscriptionPage from 'pages/TranscriptionPage';
import SegmentReportPage from 'pages/SegmentReportPage';
import SegmentsSubpage from 'pages/EventPage/subpages/SegmentsSubpage';
import NewSegmentSubpage from 'pages/EventPage/subpages/NewSegmentSubpage';
import { I18nProvider } from 'components/I18nProvider';
import { initSyncedClock } from 'lib/server-clock';
import SettingsSubpage from 'pages/EventPage/subpages/SettingsSubpage';
import './style.module.scss';
initSyncedClock();
const LiveApp = () => {
    return (_jsx(I18nProvider, { children: _jsx(ConfigProvider, { theme: theme, children: _jsx(App, { children: window.location.hash !== ''
                    ? _jsx(SubtitlesPage, { eventId: window.location.hash.slice(1) })
                    : _jsx(Outlet, {}) }) }) }));
};
const router = createBrowserRouter([
    {
        path: BASE_PATH,
        element: _jsx(LiveApp, {}),
        children: [
            {
                path: '',
                element: _jsx(HomePage, {}),
            },
            {
                path: 'admin',
                element: _jsx(EventsPage, {}),
            },
            {
                path: 'admin/events/:eventId',
                element: _jsx(EventPage, {}),
                children: [
                    {
                        path: '',
                        element: _jsx(NewSegmentSubpage, {}),
                    },
                    {
                        path: 'segments',
                        element: _jsx(SegmentsSubpage, {}),
                    },
                    {
                        path: 'settings',
                        element: _jsx(SettingsSubpage, {}),
                    },
                ],
            },
            {
                path: 'admin/events/:eventId/segments/:segmentId/transcriptions/:lang',
                element: _jsx(TranscriptionPage, {}),
            },
            {
                path: 'admin/events/:eventId/segments/:segmentId/report',
                element: _jsx(SegmentReportPage, {}),
            }
        ]
    },
]);
createRoot(document.querySelector('#app')).render(_jsx(RouterProvider, { router: router }));
