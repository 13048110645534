import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, App } from "antd";
import { updateEventId } from "api/events";
import { useI18n } from "components/I18nProvider";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEventContext } from "pages/EventPage/event-context";
import DangerZone from "./DangerZone";
import { fetchConfig } from "api/config";
import { buildPath } from "lib/resolveUrl";
import EventCodeForm from "components/EventCodeForm";
import styles from './style.module.scss';
const SettingsSubpage = () => {
    const { intl } = useI18n();
    const { eventId } = useParams();
    const navigate = useNavigate();
    const { message } = App.useApp();
    const { event, setEvent } = useEventContext();
    const [urlField, setUrlField] = useState("ready");
    const [eventOrigin, setEventOrigin] = useState(intl.formatMessage({ id: 'loading' }));
    if (eventId === undefined) {
        return _jsx("p", { children: intl.formatMessage({ id: 'loading' }) });
    }
    useEffect(() => {
        const fetchConfigState = async () => {
            const config = await fetchConfig();
            setEventOrigin(config.publicEventOrigin);
        };
        void fetchConfigState();
    }, []);
    const handleSubmit = async (code) => {
        setUrlField("saving");
        const result = await updateEventId(eventId, code);
        if (result === 'already-exists') {
            void message.error(intl.formatMessage({ id: 'eventIdExists' }));
        }
        else {
            navigate(buildPath(`admin/events/${code}/settings`), { replace: true });
            setEvent({ ...event, id: code });
        }
        setUrlField("ready");
    };
    const inputLead = _jsxs("span", { className: styles.urlBase, children: [eventOrigin, "#"] });
    return (_jsxs(Card, { title: intl.formatMessage({ id: 'settings' }), children: [_jsx(EventCodeForm, { initialCode: event.id, buttonLoading: urlField === "saving", inputLead: inputLead, submitLabel: intl.formatMessage({ id: 'set' }), onSubmit: handleSubmit }), _jsx(DangerZone, { eventId: event.id })] }));
};
export default SettingsSubpage;
