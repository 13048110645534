import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { Popover, Select, Space } from "antd";
import { buildPath } from "lib/resolveUrl";
import { SettingOutlined } from "@ant-design/icons";
import { useI18n } from "components/I18nProvider";
import { Link } from "react-router-dom";
import TestingMode from "components/TestingMode";
import styles from './style.module.scss';
import HeaderLogo from "components/HeaderLogo";
const PageHeader = ({}) => {
    const { intl, changeLocale } = useI18n();
    const menuContent = (_jsxs(Space, { children: [`${intl.formatMessage({ id: 'language' })}:`, _jsx(Select, { onChange: changeLocale, value: intl.locale, size: "small", options: [
                    {
                        value: 'en',
                        label: intl.formatMessage({ id: 'en-US' })
                    },
                    {
                        value: 'cs',
                        label: intl.formatMessage({ id: 'cs-CZ' })
                    },
                ] })] }));
    return (_jsx("div", { className: styles.header, children: _jsxs("div", { className: clsx('container', styles.headerContainer), children: [_jsxs("div", { className: styles.brand, children: [_jsx(HeaderLogo, {}), _jsx(Link, { to: buildPath(), className: styles.appName, children: "BeeyLive" })] }), _jsx(TestingMode, {}), _jsx("div", { className: styles.toolbar, children: _jsx(Popover, { trigger: ['click'], content: menuContent, overlayClassName: styles.popover, arrow: false, placement: "bottomRight", children: _jsx(SettingOutlined, { className: styles.settings }) }) })] }) }));
};
export default PageHeader;
