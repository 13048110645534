import { authFetch } from "./base";
export const startSegment = async (eventId, settings) => {
    const response = await authFetch(`api/startSegment`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ eventId, ...settings }),
    });
    if (!response.ok) {
        throw new Error(`Failed to start recording: ${(await response.json()).message}`);
    }
    const json = await response.json();
    return json.data;
};
export const fetchSegments = async (eventId) => {
    const response = await authFetch(`api/events/${eventId}/segments`);
    if (!response.ok) {
        throw new Error(`Failed to fetch segments: ${(await response.json()).message}`);
    }
    const json = await response.json();
    return json.data;
};
export const fetchActiveSegment = async (eventId) => {
    const response = await authFetch(`api/events/${eventId}/activeSegment`);
    if (!response.ok) {
        throw new Error(`Failed to fetch active segment: ${(await response.json()).message}`);
    }
    const json = await response.json();
    return json.data;
};
export const forceStopSegment = async (eventId, segmentId) => {
    const response = await authFetch(`api/events/${eventId}/segments/${segmentId}/forceStop`, {
        method: 'POST',
    });
    if (!response.ok) {
        throw new Error(`Failed to force stop segment: ${(await response.json()).message}`);
    }
    const json = await response.json();
    return json.data;
};
export const fetchTranscription = async (eventId, segmentId, lang) => {
    const response = await authFetch(`api/events/${eventId}/segments/${segmentId}/transcriptions/${lang}`);
    if (!response.ok) {
        return null;
    }
    return response.text();
};
export const fetchSegmentClients = async (eventId, segmentId) => {
    const response = await authFetch(`api/events/${eventId}/segments/${segmentId}/clients`);
    if (!response.ok) {
        return null;
    }
    const json = await response.json();
    return json.data;
};
export const fetchClientLatencies = async (eventId, segmentId, clientId) => {
    const response = await authFetch(`api/events/${eventId}/segments/${segmentId}/latencies/${clientId}`);
    if (!response.ok) {
        return null;
    }
    return response.blob();
};
