import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Card } from 'antd';
import { useI18n } from 'components/I18nProvider';
import Page from 'components/Page';
import EventCodeForm from 'components/EventCodeForm';
const HomePage = () => {
    const { intl } = useI18n();
    const handelSubmit = (code) => {
        window.open(`#${code}`, '_blank');
    };
    const inputLead = _jsxs("span", { children: [intl.formatMessage({ id: 'code' }), ":"] });
    return (_jsx(Page, { children: _jsx(Card, { title: intl.formatMessage({ id: 'joinEvent' }), children: _jsx(EventCodeForm, { inputLead: inputLead, submitLabel: intl.formatMessage({ id: 'join' }), gap: "small", autoFocus: true, onSubmit: handelSubmit }) }) }));
};
export default HomePage;
