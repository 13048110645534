import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HeaderLogo from "components/HeaderLogo";
import { Popover } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import TestingMode from "components/TestingMode";
import styles from './style.module.scss';
const SubtitlesHeader = ({ latency }) => {
    return (_jsxs("div", { className: styles.subtitlesHeader, children: [_jsx(HeaderLogo, {}), _jsx("div", { className: styles.appName, children: "BeeyLive" }), _jsx(TestingMode, {}), latency === 'none'
                ? null
                : latency === 'waiting' ? (_jsxs("div", { className: styles.latency, children: [_jsx(Loading3QuartersOutlined, { spin: true }), " ms"] })) : (_jsx(Popover, { content: _jsxs("div", { children: [_jsxs("div", { children: ["Transmit: ", latency.transmit, " ms"] }), _jsxs("div", { children: ["Transcribe: ", latency.transcribe, " ms"] }), _jsxs("div", { children: ["Translate: ", latency.translate, " ms"] })] }), children: _jsxs("div", { className: styles.latency, children: [latency.e2e, " ms"] }) }))] }));
};
export default SubtitlesHeader;
