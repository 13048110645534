import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAllEvents } from 'api/events';
import { Button, Card } from 'antd';
import NewEventDialog from './NewEventDialog';
import Page from 'components/Page';
import { Link } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';
import { useI18n } from 'components/I18nProvider';
import styles from './style.module.scss';
import { buildPath } from 'lib/resolveUrl';
const EventsPage = () => {
    const { intl } = useI18n();
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [newEventDialogOpened, setNewEventDialogOpened] = useState(false);
    useEffect(() => {
        const fetchEvents = async () => {
            const events = await fetchAllEvents();
            setEvents(events);
        };
        fetchEvents();
    }, []);
    const handleOpenNewEventDialog = () => {
        setNewEventDialogOpened(true);
    };
    const handleNewEvent = (event) => {
        navigate(buildPath(`admin/events/${event.id}`));
    };
    const handleCloseNewEventDialog = () => {
        setNewEventDialogOpened(false);
    };
    return (_jsxs(Page, { children: [_jsx("div", { className: styles.toolbar, children: _jsx(Button, { type: "primary", onClick: handleOpenNewEventDialog, children: intl.formatMessage({ id: 'newEvent' }) }) }), _jsx(Card, { title: intl.formatMessage({ id: 'events' }), children: events.map((event) => (_jsxs("div", { className: styles.eventItem, children: [_jsx(CalendarOutlined, {}), _jsx(Link, { to: buildPath(`admin/events/${event.id}`), children: event.title })] }, event.id))) }), _jsx(NewEventDialog, { open: newEventDialogOpened, onNewEvent: handleNewEvent, onClose: handleCloseNewEventDialog })] }));
};
export default EventsPage;
