import { jsx as _jsx } from "react/jsx-runtime";
import styles from './style.module.scss';
export const isTestingMode = () => {
    return localStorage.getItem('testingMode') === 'true';
};
export const toggleTestingMode = () => {
    if (localStorage.getItem('testingMode') === 'true') {
        localStorage.removeItem('testingMode');
        alert('Testing mode disabled');
        window.location.reload();
        return;
    }
    const password = window.prompt('Enter testing mode password');
    if (password === null) {
        return;
    }
    if (password === 'jelen') {
        localStorage.setItem('testingMode', 'true');
        alert('Testing mode enabled');
        window.location.reload();
    }
    else {
        alert('Incorrect password');
    }
};
const TestingMode = () => {
    if (isTestingMode()) {
        return _jsx("div", { className: styles.testingMode, children: "TESTING MODE" });
    }
    return null;
};
export default TestingMode;
