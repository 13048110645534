import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useI18n } from "components/I18nProvider";
import styles from './style.module.scss';
import { CheckCircleOutlined, DeleteOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { App, Flex, Popconfirm } from "antd";
import { deleteSegment } from "api/events";
import { buildPath } from "lib/resolveUrl";
import { isTestingMode } from "components/TestingMode";
const SegmentItem = ({ segment, reloadSegments }) => {
    const { intl } = useI18n();
    const { message } = App.useApp();
    const onDeleteSegment = async () => {
        try {
            await deleteSegment(segment.eventId, segment.id);
            await reloadSegments();
            void message.success(intl.formatMessage({ id: 'eventDeleted' }));
        }
        catch (error) {
            void message.error(intl.formatMessage({ id: 'eventDeleteFailed' }));
        }
    };
    return (_jsxs("div", { className: styles.segmentItem, children: [_jsxs("div", { children: [_jsxs(Flex, { align: "baseline", gap: "middle", children: [_jsx("h3", { children: segment.title }), _jsx(Popconfirm, { title: intl.formatMessage({ id: 'deleteSegment' }), description: intl.formatMessage({ id: 'deleteSegmentInfo' }), okText: intl.formatMessage({ id: 'delete' }), cancelText: intl.formatMessage({ id: 'cancel' }), onConfirm: onDeleteSegment, okType: "danger", children: _jsx(DeleteOutlined, { className: styles.deleteIcon }) })] }), _jsx("p", { children: segment.description }), isTestingMode() && (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.transcriptions, children: [_jsxs("span", { children: [intl.formatMessage({ id: 'original' }), ":"] }), _jsx("a", { href: buildPath(`admin/events/${segment.eventId}/segments/${segment.id}/transcriptions/${segment.audioLanguage}`), children: intl.formatMessage({ id: segment.audioLanguage }) })] }), segment.translation !== 'off' && (_jsxs("div", { className: styles.transcriptions, children: [_jsxs("span", { children: [intl.formatMessage({ id: 'translated' }), ":"] }), segment.translation.languages.map((lang) => (_jsx("a", { href: buildPath(`admin/events/${segment.eventId}/segments/${segment.id}/transcriptions/${lang}`), children: intl.formatMessage({ id: lang }) })))] })), _jsx("div", { className: styles.transcriptions, children: _jsx("a", { href: buildPath(`admin/events/${segment.eventId}/segments/${segment.id}/report`), children: intl.formatMessage({ id: 'latencyReport' }) }) })] }))] }), segment.status === 'recording' && _jsx(Loading3QuartersOutlined, { className: styles.icon, spin: true }), segment.status === 'completed' && _jsx(CheckCircleOutlined, { className: styles.icon })] }));
};
export default SegmentItem;
